import * as THREE from 'three';
import {ElementRef, Injectable, NgZone, OnDestroy} from '@angular/core';
import vertexShader from '../../../assets/shaders/vertex.glsl';
import fragmentShader from '../../../assets/shaders/fragment.glsl';
import atmosphereVertexShader from '../../../assets/shaders/atmosphereVertex.glsl';
import atmosphereFragmentShader from '../../../assets/shaders/atmosphereFragment.glsl';
import { OrbitControls } from "three-orbitcontrols-ts";


export { THREE };

@Injectable({providedIn: 'root'})
export class StarsService implements OnDestroy {
  private controls=null;
  private canvas: HTMLCanvasElement;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private light: THREE.AmbientLight;
  vertexShader:string;
  fragmentShader:string;
  atmosphereVertexShader:string;
  atmosphereFragmentShader:string;
   private sphere: THREE.Mesh;
  private group=new THREE.Group();
  //private starGeometry= new THREE.BufferGeometry();
  private frameId: number = null;
//mousePosition = new THREE.Vector3(0, 0, 0.5);

  public constructor(private ngZone: NgZone) {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      95, window.innerWidth/window.innerHeight, 0.1, 1000
    );
    
   //this.addMarker();
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
    if (this.renderer != null) {
      this.renderer.dispose();
      this.renderer = null;
      this.canvas = null;
    }
  }

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,    // transparent background
      antialias: true // smooth edges
    });

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.camera.lookAt(this.scene.position);
    this.camera.position.set(0,0,10);

    this.controls.autoRotate = true;
    this.controls.autoRotateSpeed = 0.03;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1;
    this.controls.rotateSpeed = 0.1;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 200;
    this.controls.maxPolarAngle = Math.PI/2 - .04;

    this.scene.add(this.camera);
    this.onDemandLight();
    this.onDemandGlobe();
    this.onDemandAtmosphere();
    this.configControls();
    this.controls = new OrbitControls(this.camera,this.canvas,window);
     this.OnDemendStars();
    const camera1 = new THREE.OrthographicCamera( window.innerWidth/ - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 1, 1000 );
    this.scene.add( camera1 );
        this.controls.maxZoom=2;
        this.controls.minZoom=1;
        this.controls.zoomSpeed=2;
        this.controls.dollyIn(1);
        this.controls.dollyOut(2);
        this.controls.enableZoom=true;
        this.controls.zoomChanged=true;
        this.controls.saveState();
        this.controls.target.set(0, 0, 0);
        this.controls.update();
        this.camera.updateProjectionMatrix();
//this.addMarker();

  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }
    });
  }

  public render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });

    // this.sphere.rotation.x +=  0.001;
    // this.sphere.rotation.y +=  0.001;
    //this.sphere.rotation.y += 0.003;

    // gsap.to(this.group.rotation,{
    //   x:this.mousePosition.y* 0.05,
    //   y:this.mousePosition.x* 0.05,
    //   duration:2
    // })
   // this.group.rotation.y=this.mouse.x*0.5;




  //  this.controls.update();
   //this.configControls();
    this.renderer.render(this.scene, this.camera);
    //this.configControls();
  }


  public resize(): void {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  onDoubleClick(clientX:any,clientY:any){
   var x = (clientX / window.innerWidth) * 2 - 1;
   var y = -(clientY / window.innerHeight) * 2 + 1;
   var dir = new THREE.Vector3(x, y, -1)
    dir.unproject(this.camera)

   var ray = new THREE.Raycaster(this.camera.position, dir.sub(this.camera.position).normalize())
    var intersects = ray.intersectObject(this.sphere);
    if ( intersects.length > 0 )
    {
        // alert("hit");
    }
  }

// onMouseMove(){
// }
configControls() {
  //     const camera1 = new THREE.OrthographicCamera( window.innerWidth/ - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 1, 1000 );
  // this.controls = new OrbitControls(camera1, this.canvas);
  this.controls.autoRotate = false;
  this.controls.enableZoom = true;
  this.controls.enablePan = true;
  this.controls.maxPolarAngle=Math.PI/2;
  this.controls.update();
}

//Create Stars
OnDemendStars(){
  const starGeometry= new THREE.BufferGeometry();
  const starMaterial= new THREE.PointsMaterial({
    color:0xffffff
  });

const startVertices=[];
for(let i=0;i<10000;i++){
const x=(Math.random()-0.5)*2000;
const y=(Math.random()-0.5)*2000;
const z=-(Math.random())*1000;
startVertices.push(x,y,z);
}
starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(startVertices,3));

  const stars= new THREE.Points(starGeometry,starMaterial);
  this.scene.add(stars);

}
// Create Atmosphere
onDemandAtmosphere(){

  const atmosphere=new THREE.Mesh(new THREE.SphereGeometry(5,50,50),
  new THREE.ShaderMaterial(
    {
       vertexShader:atmosphereVertexShader,
       fragmentShader:atmosphereFragmentShader,
       blending:THREE.AdditiveBlending,side:THREE.BackSide
    }
  )
  )
  atmosphere.scale.set(1.1,1.1,1.1);
  this.scene.add(atmosphere);
}
//Create Globe
onDemandGlobe(){
  //let sphere= new THREE.Mesh();
  const geometry = new THREE.SphereGeometry(5,50,50);
    const shadow= new THREE.ShaderMaterial(
      {
         vertexShader,
         fragmentShader,
         uniforms:{
          globeTexture:{
            value:new THREE.TextureLoader().load('./assets/img/globe.jpeg')
          }
         }
      }
    )
        // const material = new THREE.MeshBasicMaterial({
    //  // color: 0xff0000
    //  map:new THREE.TextureLoader().load('./assets/img/globe.jpeg')
    // });
    this.sphere = new THREE.Mesh(geometry, shadow);
    //this.scene.add(this.sphere);


 this.group.add(this.sphere);
this.scene.add(this.group);
}
//Add soft light
onDemandLight(){
      // soft white light
      this.light = new THREE.AmbientLight(0x404040);
      this.light.position.z = 10;
      this.scene.add(this.light);
}
}
