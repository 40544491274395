import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EngineService } from '../engine.service';
import { StarsService } from './stars.service';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {

  @ViewChild('rendererStarCanvas', {static: true})
  public rendererCanvas: ElementRef<HTMLCanvasElement>;
  public constructor(private engServ: StarsService) {
  }

  public ngOnInit(): void {
    
    this.engServ.createScene(this.rendererCanvas);
    this.engServ.animate();
  }
}
