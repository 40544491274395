import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {EngineService} from './engine.service';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html'
})
export class EngineComponent implements OnInit {

  @ViewChild('rendererCanvas', {static: true})
  public rendererCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('rendererCanvas1', {static: true})
  public rendererCanvas1: ElementRef<HTMLCanvasElement>;
  lastMove:any=[0,0];
  public constructor(private engServ: EngineService) {
  }

  public ngOnInit(): void {
    this.engServ.createScene(this.rendererCanvas);
    this.engServ.animate();

    // this.engServ.createScene1(this.rendererCanvas1);
    // this.engServ.animate1();
  }
  // @HostListener('document:mousemove', ['$event']) 
  // onMouseMove(e) {
  //   //console.log(e);
  //   this.engServ.mouse.x=(e.clientX/innerWidth)*2-1;
  //   this.engServ.mouse.y=-(e.clientY/innerHeight)*2+1;
  // }
  // @HostListener('document:mousedown', ['$event']) 
  // onMouseDown(e) {
  //   this.engServ.E.down=!0;
  //   this.engServ.E.xPrev=e.clientX;
  //   this.engServ.E.yPrev=e.clientY/innerHeight;

  // }
  // @HostListener('document:mousedown', ['$event']) 
  // onMouseMove(e) {
     


  //    if(!e) return;
  //    this.engServ.mousePosition.x = 2 * (e.clientX / window.innerWidth) - 1;
  //    this.engServ.mousePosition.y = 1 - 2 * (e.clientY / window.innerHeight);
//this.engServ.onMouseMove();









    // this.engServ.E.x=(e.clientX/innerWidth)*20-1;
    // this.engServ.E.y=-(e.clientY/innerHeight)*20+1;

    // if (innerWidth >= 1280) 
    // this.engServ.E.x = (e.clientX - innerWidth / 2) / (innerWidth / 2) * 2 - 1, 
    // this.engServ.E.y = -e.clientY / innerHeight * 2 + 1;
    //                         else {
    //                             var o = e.getBoundingClientRect().top;
    //                             this.engServ.E.x = e.clientX / innerWidth * 2 - 1, 
    //                             this.engServ.E.y = -(e.clientY - o) / innerHeight * 2 + 1, 
    //                             console.log(this.engServ.E.y)
    //                         }

    // this.engServ.E.x1 = e.clientX - this.engServ.E.xPrev;
    // this.engServ.E.y1 = e.clientY - this.engServ.E.yPrev;
                                    



  //   const moveX = ( e.clientX - this.lastMove[0]);
  // const moveY = ( e.clientY - this.lastMove[1]);
  // //rotate the globe based on distance of mouse moves (x and y) 
  // this.engServ.E.y += ( moveX * .05);
  // this.engServ.E.x += ( moveY * .05);

  // //store new position in lastMove
  // this.lastMove[0] = e.clientX;
  // this.lastMove[1] = e.clientY;
  // e.preventDefault();
  //}
  // @HostListener('document:mouseup', ['$event']) 
  // onMouseUp(e) {
  //   this.engServ.E.down=!1;
    
  // }
  // @HostListener('document:mousedown', ['$event']) 
  // onMouseMove(e) {
  //   console.log(e);
  //   this.engServ.E.x=(e.clientX/innerWidth)*20-1;
  //   this.engServ.E.y=-(e.clientY/innerHeight)*20+1;
    
  // }
// @HostListener('window:resize', ['$event'])
// onResize(event) {
// console.log(event.target.innerWidth);
//   this.engServ.resize();
// }
@HostListener('window:DOMContentLoaded', ['$event'])
DOMContentLoaded() {
  this.engServ.render();
}
@HostListener('window:dblclick', ['$event'])
onDblclick(event) {
console.log(event.target.innerWidth);
  this.engServ.onDoubleClick(event.clientX,event.clientY);
}
}
