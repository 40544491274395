import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {WebGLRenderer, Scene, Color, TextureLoader, Mesh, SphereGeometry, MeshBasicMaterial, PerspectiveCamera, MOUSE, AmbientLight, Raycaster, Vector2} from 'three';
import {MapControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {UnitsUtils, BingMapsProvider, MapView} from '../source/Main';
import * as THREE from 'three';

@Component({
  selector: 'app-engine1',
  templateUrl: './engine1.component.html',
  styleUrls: ['./engine1.component.scss']
})
export class Engine1Component implements OnInit {
  SPHERE = 0;
  active=this.SPHERE;
  private frameId: number = null;
  // Planar earth scene
 PLANE = 1;
 raycaster = new Raycaster();
// List of scenes
scenes:any;
@ViewChild('rendererCanvas', {static: true})
  public rendererCanvas: ElementRef<HTMLCanvasElement>;
  renderer: WebGLRenderer;

  constructor() { }

  ngOnInit(): void {
    this.renderer = new WebGLRenderer({
      canvas: this.rendererCanvas.nativeElement,
      antialias: true
    });
	this.scenes=[this.createWorldScene(), this.createMapScene()];
    this.animate();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var width = window.innerWidth;
    var height = window.innerHeight;
  
    this.renderer.setSize(width, height);
      
    for (let i = 0; i < this.scenes.length; i++) 
    {
      const s = this.scenes[i];
      s.camera.aspect = width / height;
      s.camera.updateProjectionMatrix();
    }
  }

  createWorldScene(): any
{
	var scene = new Scene();
	scene.background = new Color(0x000000);
    
	// Globe
	var loader = new TextureLoader();
	loader.load('./assets/img/globe.jpeg', function(texture) 
	{
		var sphere = new Mesh(new SphereGeometry(UnitsUtils.EARTH_RADIUS, 256, 256), new MeshBasicMaterial({map: texture}));
		scene.add(sphere);
	});

	var camera = new PerspectiveCamera(60, 1, 0.01, 1e8);
    
	var controls = new MapControls(camera, this.rendererCanvas.nativeElement);
	controls.minDistance = UnitsUtils.EARTH_RADIUS + 3e4;
	controls.maxDistance = UnitsUtils.EARTH_RADIUS * 1e1;
	controls.enablePan = false;
	controls.zoomSpeed = 0.7;
	controls.rotateSpeed = 0.3; 
	controls.mouseButtons = {
		LEFT: MOUSE.ROTATE,
		MIDDLE: MOUSE.DOLLY,
		RIGHT: MOUSE.PAN
	};

	// Set initial camera position 
	camera.position.set(0, 0, UnitsUtils.EARTH_RADIUS + 1e7);

	return {camera: camera, controls: controls, scene: scene};
}

createMapScene(): any
{
	var camera = new PerspectiveCamera(60, 1, 0.01, 1e12);

	var controls = new MapControls(camera, this.rendererCanvas.nativeElement);
	controls.minDistance = 1.0;
	controls.zoomSpeed = 1.0;

	var scene = new Scene();
	scene.background = new Color(0x444444);

	var provider = new BingMapsProvider('', BingMapsProvider.AERIAL); // new OpenStreetMapsProvider()

	var map = new MapView(MapView.PLANAR, provider);
	scene.add(map);
	map.updateMatrixWorld(true);

	scene.add(new AmbientLight(0x777777));

	return {camera: camera, controls: controls, scene: scene};
}

animate(): void
{
	//requestAnimationFrame(this.animate);
    this.frameId = requestAnimationFrame(() => {
		this.animate();
	  });
	const s = this.scenes[this.active];

	s.controls.update();
	this.renderer.render(s.scene, s.camera);

	const toggleDistance = 2e6;

	if (this.active === this.SPHERE) 
	{
		// Get distance to the surface of earth
		const distance = s.controls.getDistance() - UnitsUtils.EARTH_RADIUS;
		if (distance < toggleDistance) 
		{
			// Set raycaster to the camera center.
			const pointer = new Vector2(0.0, 0.0);
			this.raycaster.setFromCamera(pointer, s.camera);
            
			// Raycast from center of the camera to the sphere surface
			const intersects = this.raycaster.intersectObjects(s.scene.children);
			if (intersects.length > 0) 
			{
				const point = intersects[0].point;

				// Get coordinates from sphere surface
				const pos = UnitsUtils.vectorToDatums(point);
                
				const planeScene = this.scenes[this.PLANE];

				// Calculate plane coordinates
				var coords = UnitsUtils.datumsToSpherical(pos.latitude, pos.longitude);
				planeScene.controls.target.set(coords.x, 0, -coords.y);
				planeScene.camera.position.set(coords.x, distance, -coords.y);

				console.log('Geo-Three: Switched scene from sphere to plane.', point, pos, coords);

				// Change scene to "plane" earth
				this.active = this.PLANE;
			}
		}
	}
	else if (this.active === this.PLANE) 
	{
		const distance = s.controls.getDistance();

		s.controls.minPolarAngle = 0;
		s.controls.maxPolarAngle = Math.PI / 2;

		s.controls.minAzimuthAngle = -Math.PI;
		s.controls.maxAzimuthAngle = Math.PI;
		
		const ratio = 0.4;
		if (distance > toggleDistance * ratio) 
		{
			// Transition progres (0 to 1)
			const progress = (toggleDistance - distance) / (toggleDistance * (1 - ratio));

			// Limit polar angle
			s.controls.maxPolarAngle = progress * Math.PI / 2;
			
			// Limit range of azimuth rotation
			s.controls.minAzimuthAngle = progress * -Math.PI;
			s.controls.maxAzimuthAngle = progress * Math.PI;
		}

		if (distance > toggleDistance) 
		{
			// Datum coordinates
			const target = s.controls.target;
			const coords = UnitsUtils.sphericalToDatums(target.x, -target.z);

			// Get sphere surface point from coordinates
			const dir = UnitsUtils.datumsToVector(coords.latitude, coords.longitude);

			const sphereScene = this.scenes[this.SPHERE];

			// Set camera position 
			dir.multiplyScalar(UnitsUtils.EARTH_RADIUS + distance);
			sphereScene.camera.position.copy(dir);

			console.log('Geo-Three: Switched scene from plane to sphere.', s.controls, coords, dir);

			// Change to spherical earth model
			this.active = this.SPHERE;
		}
	}
}
public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
    if (this.renderer != null) {
      this.renderer.dispose();
      this.renderer = null;
      //this.canvas = null;
    }
  }
}
