import packageInfo from 'package.json';

export const environment = {
  production: true,
  version: packageInfo.version,
  apiUrl:"https://oneradio.online/radioapi/api/RData/",
  //apiUrl:"https://localhost:44305/api/RData/",
  GetSearch:"GetAll?searchKey=",
  GetAllFavChannels:"GetAllFavChannels?searchKey=",
  GetChannel:"GetChannel?ChannelId="
};

