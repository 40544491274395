import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [`
  .disabled-pointer-events {
      pointer-events: none;
  }
`]
})
export class AppComponent {
  disablePointerEvents = false;

    onMouseEnter() {
      console.log('asd');
        this.disablePointerEvents = true;
    }

    onMouseLeave() {
      console.log('asdfgdfg');
        this.disablePointerEvents = false;
    }
}
