import { Options } from '@angular-slider/ngx-slider';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AESEncryptDecryptService } from 'src/app/_services/AESEncryptDecrypt.service';
import { HttpService } from 'src/app/_services/http.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-ui-infobar-bottom',
  templateUrl: './ui-infobar-bottom.component.html'
})
export class UiInfobarBottomComponent implements OnInit {
  value: number = 40;
  highValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100
  };


  @ViewChild('searchKey') searchKey: ElementRef;
  activeAccordian:any;
  openaccordian:any ='0';
  radioList:any;
  favList:any;
  favChannelList:any;
  audio = new Audio();
  itemIndex:number=0;
  channelName:string="Song";
  cityName:string="City";
  countryName:string="Country";
  channelID:string;
  isPlay:boolean=false;
  favcolor: any="White";
  public lat;
  public lng;
 favListarry = [];
  @ViewChild('volumeBar') volumeBar: ElementRef;

  public constructor(private http:HttpService,private EnDe:AESEncryptDecryptService,
    private cookieService:CookieService) {
  }
  ngAfterViewInit() {
    this.openaccordian ='0';
    this.searchKey.nativeElement.focus();
   
  if(this.searchKey.nativeElement.value!= null && this.searchKey.nativeElement.value!='')
{
  this.onSearch(this.searchKey.nativeElement.value);
}
  }
  public ngOnInit(): void {
console.log(this.value);
  // this.findMe();
  }

  onchangeVolume()
  {
     this.audio.volume= this.value / 200;
  }

  onHome()
  {
    this.openaccordian ='0';
  }

  // setVolume(volume: number): void {
  //   this.audio.volume = volume;
  // }

  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('latitude',position.coords.latitude);
        console.log('longitude',position.coords.longitude);
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  //}




  onSearch(searchKey:any){
  
    this.http.getAll(environment.GetSearch+searchKey).subscribe((result=>{
    this.radioList=result;
    //console.log(this.radioList);
    }));
  }
  onPlay(ChannelId:string,url:string,i:number){
    debugger;
    this.onCheckFavorite(ChannelId);

    if(this.radioList.length>0){
      console.log(url);
    this.audio.src=url;
    this.audio.load();
    this.audio.play();
    this.itemIndex=i;
    this.channelName=this.radioList[i].channelName;
    this.cityName=this.radioList[i].cityName;
    this.countryName=this.radioList[i].countryName;
    this.channelID=this.radioList[i].channelID;
    console.log(this.channelID);
    this.isPlay=true;
    }
  }
  onValidPlay(){
    if(this.radioList.length>0){
      this.audio.src=this.radioList[this.itemIndex].url;
      this.channelName=this.radioList[this.itemIndex].channelName;
      this.cityName=this.radioList[this.itemIndex].cityName;
    this.countryName=this.radioList[this.itemIndex].countryName;
      this.audio.load();
      this.audio.play();
      this.isPlay=true;
    }
  }
  onNext(){
    if(this.radioList.length>1){
      if(this.radioList.length-1==this.itemIndex)
    this.itemIndex=0;
  else
  this.itemIndex+=1;
  this.channelName=this.radioList[this.itemIndex].channelName;
  this.cityName=this.radioList[this.itemIndex].cityName;
  this.countryName=this.radioList[this.itemIndex].countryName;
    this.audio.src=this.radioList[this.itemIndex].url;
    this.audio.load();
    this.audio.play();
    this.isPlay=true;
    }

  }
  onPrevious(){
    if(this.radioList.length>1){
    if(this.itemIndex==0)
      this.itemIndex=this.radioList.length-1;
    else if(this.itemIndex==this.radioList.length)
      this.itemIndex=0;
    else
      this.itemIndex-=1;
      this.channelName=this.radioList[this.itemIndex].channelName;
      this.cityName=this.radioList[this.itemIndex].cityName;
    this.countryName=this.radioList[this.itemIndex].countryName;
    this.audio.src=this.radioList[this.itemIndex].url;
    this.audio.load();
    this.audio.play();
    this.isPlay=true;
    }
  }
  onPause(){
    if(this.radioList.length>1){
    this.audio.pause();
    this.isPlay=false;
  }
  }

  onCheckFavorite(ChannelId:string)
  {
    if(this.cookieService.get('favChannel')!="")
        {
          this.favList=JSON.parse(this.cookieService.get('favChannel'));
           this.favChannelList = this.favList.filter(x=>x.ChannelId==ChannelId);
            if(this.favChannelList =="")
            {
              this.favcolor='White'
     
            }else
            {
              this.favcolor='Red';
     
            }
        }else
        {
          this.favcolor='White'
         
        }
  }

  getFavouriteChannel()
  {

    if(this.openaccordian=='0') 
   {
          if(this.cookieService.get('favChannel')!="")
          {
            this.favList=JSON.parse(this.cookieService.get('favChannel'));
 console.log(this.favList);
            this.favChannelList =Array.from(new Set(this.favList.map(item => item.ChannelId)));
            var StringifyData = JSON.stringify(this.favChannelList);
            this.radioList= null;
            this.http.getAll(environment.GetAllFavChannels+StringifyData).subscribe((result=>{
              debugger;
              this.radioList=result;
              console.log(this.radioList);
              }));
          //this.radioList=this.favList;
          }
          
    this.openaccordian='1';
   }
   else
   {
    this.openaccordian='0';
   }
  }
  onFavorite(ChannelId:string,channelName:string, favcolor:string){
    console.log(ChannelId);
    debugger;
    if(ChannelId != null &&  ChannelId!=""){
      console.log(ChannelId);
    if(favcolor=="Red")
    {
      if(this.cookieService.get('favChannel')!="")
      {
        this.favList=JSON.parse(this.cookieService.get('favChannel'));

         this.favChannelList = this.favList.filter(x=>x.ChannelId==ChannelId);
          if(this.favChannelList =="")
          {
            this.favList.push({ChannelId,channelName});
            var StringifyData = JSON.stringify(this.favList);
            this.cookieService.set('favChannel', StringifyData, 2, '/', null, null, 'Lax');

          }else
          {
            const index: number  = this.favList.findIndex(x=>x.ChannelId==ChannelId);


              if (index !== -1) {
                  this.favList.splice(index, 1);
              }    
           
            var StringifyData = JSON.stringify(this.favList);
            this.cookieService.set('favChannel', StringifyData, 2, '/', null, null, 'Lax');

          }
      }
      this.favcolor="White";
    }
    else
    {
        if(this.cookieService.get('favChannel')!="")
        {
          this.favList=JSON.parse(this.cookieService.get('favChannel'));

           this.favChannelList = this.favList.filter(x=>x.ChannelId==ChannelId);
            if(this.favChannelList =="")
            {
              this.favList.push({ChannelId,channelName});
              var StringifyData = JSON.stringify(this.favList);
              this.cookieService.set('favChannel', StringifyData, 2, '/', null, null, 'Lax');

            }
        }
        else
        {  
          console.log('hi');
          this.favList =null;
          if (!this.favChannelList) {
            this.favChannelList = [];
          }
          
          this.favChannelList.push({ChannelId,channelName});
          var StringifyData = JSON.stringify(this.favChannelList);
          this.cookieService.set('favChannel', StringifyData, 2, '/', null, null, 'Lax');
        }
        this.favcolor="Red";
    }
debugger;

if( this.openaccordian=='1')
{
  this.openaccordian='0';
  this.getFavouriteChannel();
}


    }
 

  }



  //       this.favList=JSON.parse(this.cookieService.get('favChannel'));


//       this.favListarry.push({ChannelId,channelName});
//       var StringifyData = JSON.stringify(this.favListarry);
// console.log(StringifyData);
// this.cookieService.set('favChannel', StringifyData, 2, '/', null, null, 'Lax');
// if(this.cookieService.get('favChannel')!=""){
//   this.favList=JSON.parse(this.cookieService.get('favChannel'));
//   console.log(this.favList);
//    this.favList = this.favList.filter(x=>x.ChannelId==ChannelId)
//   console.log(this.favList);
//   }
      // this.favList=JSON.parse(this.favListarry)
      // this.cookieService.set('favChannel', this.favListarry, 2, '/', null, null, 'Lax');
      // if(this.cookieService.get('favChannel')!=""){
      // this.favList=JSON.parse(this.cookieService.get('favChannel'));
      // }
      // else{
      //  var a= JSON.parse(this.cookieService.get('favChannel'))
      //  a.array.forEach(element => {
        
      //  });
      // }
  onFav(ChannelId:string)
  {
    this.cookieService.set('user', 'Readerstacks', 2, '/', null, null, 'Lax');

   // alert(ChannelId);

  }


}
