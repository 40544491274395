<div class="engine-wrapper" style="z-index: 1;" >
  <!-- (document:mousedown)="onMouseMove($event)" -->
  <canvas #rendererCanvas id="renderCanvas" ></canvas>
  <!-- <canvas #rendererCanvas1 id="renderCanvas1" width="640" height="480" style="position: absolute; z-index: 0">
  </canvas>
  <canvas #rendererCanvas id="renderCanvas" width="640" height="480" style="position: absolute; z-index: 1">
  </canvas> -->


</div>
<!-- (document:mousedown)="onMouseDown($event)"  -->
<!-- (document:mouseup)="onMouseUp($event)" -->
