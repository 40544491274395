import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ui-sidebar-left',
  templateUrl: './ui-sidebar-left.component.html'
})
export class UiSidebarLeftComponent implements OnInit {

  public constructor() {
  }

  public ngOnInit(): void {
  }

}
