
<div id="frontComponent"  >
    <!-- (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">  -->
    <app-ui></app-ui>
</div>

<div id="backComponent" >
          
   <app-engine></app-engine> 
    <!-- <app-engine1></app-engine1> -->
    <!-- <app-stars></app-stars> -->
   
    <div class="bgVideo"></div>
    <!-- <div class="globleRotate"style="z-index:1;position:absolute;left:0;right:0;width:100%;height:100vh;display:flex;align-items:center;justify-content:center;">
        <img src="assets/globe/final-globe.gif" style="z-index:1; width:95%;">
    </div> -->

    <!-- <div class="globe">
        <div class="go"></div>
      </div> -->
</div>

<!-- <app-engine1></app-engine1> -->
 <!-- <app-stars></app-stars> -->
 