

<nav class="p-0">

<div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item pages">

      <h2 class="accordion-header" id="flush-headingOne">
        <button class="accordion-button collapsed" type="button" (click)=" onHome()" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" role="img"  class="Svg-sc-1bi12j5-0 gSLhUO home-active-icon" viewBox="0 0 24 24">
            <path d="M21 22V7.174l-9.001-5.195L3 7.214V22h7v-7h4v7z"></path>
          </svg>
          Home
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body text-white">
         about us
        </div>
      </div>
    </div>
    <div class="accordion-item pages">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" (click)=" ngAfterViewInit()">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" role="img" class="Svg-sc-1bi12j5-0 gSLhUO search-active-icon" viewBox="0 0 24 24">
            <path d="M16.736 16.262A8.457 8.457 0 0019 10.5a8.5 8.5 0 10-3.779 7.067l4.424 5.18 1.521-1.299-4.43-5.186zM10.5 17C6.916 17 4 14.084 4 10.5S6.916 4 10.5 4 17 6.916 17 10.5 14.084 17 10.5 17z" fill="#B3B3B3"></path>
          </svg>
          City, Country
          

        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
       
        <div class="accordion-body p-0">
            <div class="input-group">
          
              <input type="text" #searchKey class="form-control" placeholder="search" aria-label="Recipient's username" aria-describedby="button-addon2" (keyup.enter)="onSearch(searchKey.value)">
              <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="onSearch(searchKey.value)" >Search</button>
            </div>
          </div>
          <div class="list p-0">
            <ng-container *ngFor="let radio of radioList; index as i">
                <div (click)="onPlay(radio.channelID,radio.url, i)">
                  <p>{{radio.channelName}}</p>
                </div>
            </ng-container>
       
        </div>
      </div>
    </div>
    <div class="accordion-item pages">
      <h2 class="accordion-header" id="flush-headingThree">
        <button class="accordion-button collapsed" type="button" (click)=" getFavouriteChannel()" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 16 16" class="Svg-sc-1bi12j5-0 gSLhUO">
            <path d="M13.764 2.727a4.057 4.057 0 00-5.488-.253.558.558 0 01-.31.112.531.531 0 01-.311-.112 4.054 4.054 0 00-5.487.253A4.05 4.05 0 00.974 5.61c0 1.089.424 2.113 1.168 2.855l4.462 5.223a1.791 1.791 0 002.726 0l4.435-5.195A4.052 4.052 0 0014.96 5.61a4.057 4.057 0 00-1.196-2.883zm-.722 5.098L8.58 13.048c-.307.36-.921.36-1.228 0L2.864 7.797a3.072 3.072 0 01-.905-2.187c0-.826.321-1.603.905-2.187a3.091 3.091 0 012.191-.913 3.05 3.05 0 011.957.709c.041.036.408.351.954.351.531 0 .906-.31.94-.34a3.075 3.075 0 014.161.192 3.1 3.1 0 01-.025 4.403z"></path>
          </svg>
          Favourite
        </button>
      </h2>
      <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body p-0">
          <div class="list">
            <ng-container *ngFor="let radio of radioList">
            <!-- <div (click)="onPlay(radio.channelID)">
              <img src="https://upload.wikimedia.org/wikipedia/tr/4/47/Darmaduman.jpg" alt="#">
              <p>radio.channelName</p> 
            </div>-->
          </ng-container>
          </div>
        </div>
        <div class="list p-0" >
          <ng-container *ngFor="let radio of radioList; index as i" style="overflow: scroll; overflow-y:scroll;">
          <div (click)="onPlay(radio.channelID,radio.url, i)">
            <p>{{radio.channelName}}</p>
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- <hr class="hr2"> -->
 
  <hr class="hr3">
  <div class="play">
    <div class="play_current d-flex align-items-center justify-content-between">
        <div class="songname">
            <p class="mb-0">{{channelName}}</p>
            <span>{{cityName}} {{countryName}}</span>
        </div>
        <div class="favIc"  >
         
          <svg 
          (click)="onFavorite(channelID,channelName,favcolor);" xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 24 24">
          <path *ngIf="favcolor === 'White'" d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853ZM18.827 6.1701C17.3279 4.66794 14.9076 4.60701 13.337 6.01687L12.0019 7.21524L10.6661 6.01781C9.09098 4.60597 6.67506 4.66808 5.17157 6.17157C3.68183 7.66131 3.60704 10.0473 4.97993 11.6232L11.9999 18.6543L19.0201 11.6232C20.3935 10.0467 20.319 7.66525 18.827 6.1701Z" fill="rgba(255,0,0,1)"></path>
           <path *ngIf="favcolor === 'Red'" d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z" fill="#dd0808"></path>
        </svg> </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <!-- <a class="name">{{channelName}}</a> -->
      <div class="controls d-flex align-items-center">
        <svg (click)="onPrevious();" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 16 16" class="Svg-sc-1bi12j5-0 gSLhUO"><path d="M13 2.5L5 7.119V3H3v10h2V8.881l8 4.619z"></path></svg>
        <svg *ngIf="!isPlay" (click)="onValidPlay();" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 16 16" class="Svg-sc-1bi12j5-0 gSLhUO play-btn"><path d="M4.018 14L14.41 8 4.018 2z"></path></svg>
        <svg *ngIf="isPlay" (click)="onPause();" xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
        <svg (click)="onNext();"xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 16 16" class="Svg-sc-1bi12j5-0 gSLhUO"><path d="M11 3v4.119L3 2.5v11l8-4.619V13h2V3z" ></path></svg>
      </div>
      <div class="progressVolume" style="width: 110px;">
        <!-- <input type="range" class="form-range" #rangevalue (onchange)="onchange(rangevalue)" id="customRange1"> -->
        <!-- <ngx-slider [(value)]="value" [(highValue)]="highValue"  (valueChange)="onchangeVolume();" [options]="options"></ngx-slider> -->
        <ngx-slider [(value)]="value" (valueChange)="onchangeVolume();" [options]="options"></ngx-slider>

      </div>
    </div>
  </div>
</nav>